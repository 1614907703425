import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "rgba(240,240,240,0.85)",
    color: "#333333",
  },
  toolbar: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  titleLogoBox: {
    display: "inline-grid",
    alignItems: "center",
    minHeight: "64px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "40px",
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "100px",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "200px",
    },
    marginRight: "2em",
    marginBottom: 0,
    cursor: "pointer",
    padding: 0,
  },
  titleLogoButton: {
    padding: "0 0 0 20px",
    borderRadius: "0",
    borderRight: "1px solid #dddddd",
  },
  headerLink: {
    minHeight: "64px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "70px",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "200px",
    },
    borderRadius: "0",
    borderRight: "1px solid #dddddd",
    "&:hover": {
      backgroundColor: "rgba(0, 150, 136, 0.85)",
      color: "#ffffff",
    },
  },
  headerExternalLink: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  headerMail: {
    minHeight: "64px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "64px",
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "70px",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "200px",
    },
    borderRadius: "0",
    paddingRight: "24px",
    "& span": {
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.1rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1.3rem",
      },
    },
  },
  menuRight: {
    marginLeft: "auto",
  },
}))
