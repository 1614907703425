import React from "react"
import {
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  Fab,
  Link,
  Typography,
} from "@material-ui/core"
import { Mail as MailIcon } from "@material-ui/icons"

import { useStyles } from "./style/footerStyle"
import { supportUrl } from "../Common/utility"

const Footer = props => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Link
            href={supportUrl}
            rel="noopener noreferrer"
            target="_blank"
            className={classes.footerExternalLink}
          >
            <Fab
              color="primary"
              variant="extended"
              aria-label="mail button"
              className={classes.fabButton}
            >
              <MailIcon />　
              <Typography variant="button">ご相談はこちら</Typography>
            </Fab>
          </Link>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Footer
