/**
 * className複数クラス指定
 * @param data [array] 複数クラス（配列）
 */
export const multiClass = arr => {
  const reducer = (accumulator, currentValue) =>
    accumulator + " " + currentValue
  return arr.reduce(reducer)
}

/**
 * ピクセルからremに変換
 */
export const pxToRem = value => {
  return `${value / 16}rem`
}

/**
 * 問い合わせフォームURL
 */
export const supportUrl = "https://www.kik.co.jp/website-design-support/"
