import React from "react"
import { Button, Typography } from "@material-ui/core"

import { useStyles } from "./style/headerStyle"

const HeaderLink = ({ title }) => {
  const classes = useStyles()

  return (
    <Button className={classes.headerLink} color="primary">
      <Typography variant="h6">{title}</Typography>
    </Button>
  )
}

export default HeaderLink
