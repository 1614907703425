import PropTypes from "prop-types"
import React from "react"
import { Link as GatsbyLink } from "gatsby"
import {
  Box,
  Button,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Link,
} from "@material-ui/core"
import withWidth, { isWidthUp } from "@material-ui/core/withWidth"
import { Mail as MailIcon } from "@material-ui/icons"
import { Link as ScrollLink } from "react-scroll"

import Image from "../Image/image"
import HeaderLink from "./headerLink"

import { useStyles } from "./style/headerStyle"
import { supportUrl } from "../Common/utility"

const Header = props => {
  const classes = useStyles()
  const page = props.page

  const getLogo = () => {
    if (isWidthUp("xl", props.width)) {
      return "kiklogo_200x40.png"
    }
    if (isWidthUp("lg", props.width)) {
      return "kiklogo_200x40.png"
    }
    if (isWidthUp("md", props.width)) {
      return "kiklogo_200x40.png"
    }
    if (isWidthUp("sm", props.width)) {
      return "kiklogo_200x40.png"
    }
    return "kiklogo.png"
  }

  const getMailTitle = () => {
    if (isWidthUp("xl", props.width)) {
      return "ご相談はこちら"
    }
    if (isWidthUp("lg", props.width)) {
      return "ご相談はこちら"
    }
    if (isWidthUp("md", props.width)) {
      return "ご相談はこちら"
    }
    if (isWidthUp("sm", props.width)) {
      return "ご相談はこちら"
    }
    return ""
  }

  return (
    <Box className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Button className={classes.titleLogoButton} href="" color="primary">
            <Link
              href={"https://www.kik.co.jp/"}
              rel="noopener noreferrer"
              target="_blank"
              className={classes.headerExternalLink}
            >
              <Box className={classes.titleLogoBox}>
                <Image assetUrl={getLogo()} alt="共進社印刷ロゴ" />
              </Box>
            </Link>
          </Button>
          <Box>
            {page === "index" ? (
              <ScrollLink
                activeClass="active"
                to="production"
                spy={true}
                smooth={true}
                offset={-70}
                duration={800}
              >
                <HeaderLink title={"制作実績"} />
              </ScrollLink>
            ) : (
              <GatsbyLink to={"/"} className={classes.headerExternalLink}>
                <HeaderLink title={"サイト制作"} />
              </GatsbyLink>
            )}
            <Link
              href={"https://www.kik.co.jp/company-info/"}
              rel="noopener noreferrer"
              target="_blank"
              className={classes.headerExternalLink}
            >
              <HeaderLink title={"会社概要"} />
            </Link>
          </Box>
          <Box className={classes.menuRight}>
            <Link
              href={supportUrl}
              rel="noopener noreferrer"
              target="_blank"
              className={classes.headerExternalLink}
            >
              <Button
                variant="contained"
                className={classes.headerMail}
                href={""}
                color="primary"
                startIcon={<MailIcon />}
              >
                <Typography variant="button">{getMailTitle()}</Typography>
              </Button>
              <Box></Box>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default withWidth()(Header)
