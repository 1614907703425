import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    top: "auto",
    bottom: 0,
    backgroundColor: "rgba(255,255,255,0.25)",
    color: "#333333",
    boxShadow: "none",
  },
  toolbar: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
    borderRadius: "0",
    height: "60px",
  },
  footerExternalLink: {
    "&:hover": {
      textDecoration: "none",
    },
  },
}))
